<template>
    <div class="LoginForm">
        <div class="center">
      <h1>Login</h1>
      <div class="form">
        <div class="txt_field">
          <input type="text" v-model="username" required>
          <span></span>
          <label>Username</label>
        </div>
        <div class="txt_field">
          <input type="password" v-model="password" required>
          <span></span>
          <label>Password</label>
        </div>
        <!-- <div class="pass">Forgot Password?</div> -->
        <input type="submit" value="Login" @click="btnLogin()">
        <div class="err_validate">{{ mess }}</div>
        <!-- <div class="signup_link">
          Not a member? <a href="#">Signup</a>
        </div> -->
      </div>
    </div>
    </div>
</template>
  
<script>
    // import { UserLogin } from '@/api/UserLogin';
    import axios from 'axios'
    export default {
        name: 'AdminLogin',
        data() {
            return {
                username:'',
                password:'',
                mess:'',
                pageTitle:'Login',
            }
        },
        methods:{
            btnLogin(){
                if(this.username.trim().length > 0 && this.password.trim().length > 0)
                {
                    this.mess = ""
                    this.UserLogin(this.username,this.password)
                }else{
                    this.mess = "Vui lòng nhập tài khoản và mật khẩu"
                }
            },
             UserLogin(username, password) {
                const data = {
                username: username,
                password: password
                };
                
                axios.post('https://superauto.click/api/v1/auth/login', data)
                .then(response => {
                    let err = response.data.err
                    if(err==0) {
                        this.mess = ""
                        this.SaveTokenBearer(response.data.access_token)
                        localStorage.setItem('Login', true);
                        this.$router.push('/dashboard');
                        // console.log(response.data) 
                    }else{
                        this.mess = 'Sai tài khoản hoặc mật khẩu'
                        // console.log(response.data) 
                    }
                })
                .catch(error => {
                    this.mess = 'Đang bảo trì'
                    console.log(error);
                });
            },
            SaveTokenBearer(token){
                localStorage.setItem('token', token);
            }
            
        },
        mounted(){
          document.title = this.pageTitle;
        },
    }
</script>
  
<style scoped>

.LoginForm{
  margin: 0;
  padding: 0;
  background: linear-gradient(120deg,#2980b9, #8e44ad);
  height: 100vh;
  overflow: hidden;
}
.center{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 350px;
  background: white;
  border-radius: 10px;
  box-shadow: 10px 10px 15px rgba(0,0,0,0.05);
}
.center h1{
  text-align: center;
  padding: 20px 0;
  border-bottom: 1px solid silver;
}
.center .form{
  padding: 0px 25px 40px;
  box-sizing: border-box;
}
.form .txt_field{
  position: relative;
  border-bottom: 2px solid #adadad;
  margin: 30px 0;
}
.txt_field input{
  width: 100%;
  padding: 0 5px;
  height: 40px;
  font-size: 16px;
  border: none;
  background: none;
  outline: none;
}
.txt_field label{
  position: absolute;
  top: 50%;
  left: 5px;
  color: #adadad;
  transform: translateY(-50%);
  font-size: 16px;
  pointer-events: none;
  transition: .5s;
}
.txt_field span::before{
  content: '';
  position: absolute;
  top: 40px;
  left: 0;
  width: 0%;
  height: 2px;
  background: #2691d9;
  transition: .5s;
}
.txt_field input:focus ~ label,
.txt_field input:valid ~ label{
  top: -5px;
  color: #2691d9;
}
.txt_field input:focus ~ span::before,
.txt_field input:valid ~ span::before{
  width: 100%;
}
/* .pass{
  margin: -5px 0 20px 5px;
  color: #a6a6a6;
  cursor: pointer;
}
.pass:hover{
  text-decoration: underline;
} */
input[type="submit"]{
  width: 100%;
  height: 50px;
  border: 1px solid;
  background: #2691d9;
  border-radius: 25px;
  font-size: 18px;
  color: #e9f4fb;
  font-weight: 700;
  cursor: pointer;
  outline: none;
}
input[type="submit"]:hover{
  border-color: #2691d9;
  transition: .5s;
}
/* .signup_link{
  margin: 30px 0;
  text-align: center;
  font-size: 16px;
  color: #666666;
}
.signup_link a{
  color: #2691d9;
  text-decoration: none;
}
.signup_link a:hover{
  text-decoration: underline;
} */
.err_validate{
    color: red;
    font-size: 15px;
    padding: 5px;
    text-align: center;
}
</style>
  