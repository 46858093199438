<template>
    <div class="dashboard">
        <Navbar></Navbar>
        <div class="main">
            <div class="info">
                <div class="key">
                    <div class="title_fil">
                        <div class="title_key"><span>Danh sách key /</span><span class="btnAddKey" @click="addkeys"
                            >Thêm key</span></div> 
                        <div class="filByUsername">
                            <input type="text" v-model="keyFil">
                            <button>Tìm</button>
                        </div>
                    </div>
                        <div class="listKey">
                        <!-- <ul>
                            <li v-for="(item,index) in dataKey" :key="index"> {{ item }}</li>
                        </ul> -->
                        <table>
                        <tr>
                            <th>Thao tác</th>
                            <th>TK</th>
                            <th>MK</th>
                            <th>Mã máy</th>
                            <th>Người tạo</th>
                            <th>ATS-basic</th>
                            <th>ATS-DD</th>
                            <th>ATS-DG</th>
                            <th>ATS-CR</th>
                            <th>ATL-basic</th>
                            <th>ATL-DD</th>
                            <th>ATL-DG</th>
                            <th>ATL-CR</th>
                            <th>AT-tiktok</th>
                        </tr>
                        <tr v-for="(item,index) in dataKey" :key="index">
                            <td class="edit_delete">
                                <div class="EditKey" @click="editKeys(item)">
                                    Sửa
                                </div>
                                <div class="DeleteKey" @click="delKey(item)">
                                    Xóa
                                </div>
                            </td>
                            <td>{{ item.username }}</td>
                            <td>{{ item.password }}</td>
                            <td>{{ item.codePc }}</td>
                            <td>{{ item.userCreate }}</td>
                            <td>{{ item.autoBasicShopee }}</td>
                            <td>{{ item.autoDDShopee }}</td>
                            <td>{{ item.autoDGShopee }}</td>
                            <td>{{ item.autoCrawShopee }}</td>
                            <td>{{ item.autoBasicLazada }}</td>
                            <td>{{ item.autoDDLazada }}</td>
                            <td>{{ item.autoDGLazada }}</td>
                            <td>{{ item.autoCrawLazada }}</td>
                            <td>{{ item.autoTiktok }}</td>
                            
                        </tr>
                        </table>
                    </div>
                </div>
                <div class="user">
                    
                </div>
                <div class="addOrEditKey" v-show="addOrEditKey">
                    <div class="infoKeyAdd">
                        <div class="tk_mk_code">
                            <div class="tk_mk_code_key">
                                <label for="">TK: </label>
                                <input type="text" v-model="key.username">
                            </div>
                            <div class="tk_mk_code_key">
                                <label for="">MK: </label>
                                <input type="text" v-model="key.password">
                            </div>
                            <div class="tk_mk_code_key">
                                <label for="">Code: </label>
                                <input type="text" v-model="key.codePc">
                            </div>
                        </div>
                        <div class="toolAuto">
                            <div class="tool_SL">
                                <label for="autoBasicShopee">autoBasicShopee:</label>
                                <select v-model="key.autoBasicShopee">
                                    <option :value="true">Mở</option>
                                    <option :value="false">Đóng</option>
                                </select>
                            </div>
                            <div class="tool_SL">
                                <label for="autoDDShopee">autoDDShopee:</label>
                                <select v-model="key.autoDDShopee">
                                    <option :value="true">Mở</option>
                                    <option :value="false">Đóng</option>
                                </select>
                            </div> 
                            <div class="tool_SL">
                                <label for="autoDGShopee">autoDGShopee:</label>
                                <select v-model="key.autoDGShopee">
                                    <option :value="true">Mở</option>
                                    <option :value="false">Đóng</option>
                                </select>
                            </div> 
                            <div class="tool_SL">
                                <label for="autoCrawShopee">autoCrawShopee:</label>
                                <select v-model="key.autoCrawShopee">
                                    <option :value="true">Mở</option>
                                    <option :value="false">Đóng</option>
                                </select>
                            </div> 
                            <div class="tool_SL">
                                <label for="autoBasicLazada">autoBasicLazada:</label>
                                <select v-model="key.autoBasicLazada">
                                    <option :value="true">Mở</option>
                                    <option :value="false">Đóng</option>
                                </select>
                            </div> 
                            <div class="tool_SL">
                                <label for="autoDDLazada">autoDDLazada:</label>
                                <select v-model="key.autoDDLazada">
                                    <option :value="true">Mở</option>
                                    <option :value="false">Đóng</option>
                                </select>
                            </div> 
                            <div class="tool_SL">
                                <label for="autoDGLazada">autoDGLazada:</label>
                                <select v-model="key.autoDGLazada">
                                    <option :value="true">Mở</option>
                                    <option :value="false">Đóng</option>
                                </select>
                            </div> 
                            <div class="tool_SL">
                                <label for="autoCrawLazada">autoCrawLazada:</label>
                                <select v-model="key.autoCrawLazada">
                                    <option :value="true">Mở</option>
                                    <option :value="false">Đóng</option>
                                </select>
                            </div> 
                            <div class="tool_SL">
                                <label for="autoTiktok">autoTiktok:</label>
                                <select v-model="key.autoTiktok">
                                    <option :value="true">Mở</option>
                                    <option :value="false">Đóng</option>
                                </select>
                            </div>
                        </div>
                        <div class="validateAddKey">
                            {{ mes }}
                        </div>
                        <div class="btn_add_cancel_edit">
                            <div v-if="AddKey">
                                <button @click="addDataKey">Thêm</button>
                            </div>
                            <div v-else>
                                <button @click="editDataKey">Lưu</button>
                            </div>
                            <button @click="cancel">Hủy</button>
                        </div>
                    </div>
                </div>
                <div class="deteteKeyAccess" v-show="daleteKeyShow">
                    <span>Bạn có chắc muốn xóa tài khoản: {{ key.username }} ?</span>
                    <div class="access_cancel">
                        <button @click="AcceptDeleteKey">xóa</button>
                        <button @click="cancel()">hủy</button>
                    </div>
                </div>
                <div class="mask" v-if="mask">
                </div>

            </div>
        </div>
    </div>
</template>
  
<script>
import axios from 'axios'
import Navbar from './navbar.vue';
    export default {
        name: 'AdminDashboard',
        components:{ Navbar },
        data(){
            return{
                keyFil:'',
                key:{
                    id:0,
                    username:'',
                    password:'',
                    codePc:'',
                    userCreate: localStorage.getItem('username'),
                    autoBasicShopee: false,
                    autoDDShopee: false,
                    autoDGShopee:false,
                    autoCrawShopee:false,
                    autoBasicLazada:false,
                    autoDDLazada: false,
                    autoDGLazada: false,
                    autoCrawLazada:false,
                    autoTiktok:false,
                },
                mask:false,
                mes:'',
                addOrEditKey: false,
                EditKey:false,
                AddKey:false,
                daleteKeyShow:false,
                pageTitle:'Dashboard',
                username: localStorage.getItem('username'),
                token: localStorage.getItem('token'),
                dataKey:[]
            }
        },
        methods:{
            cancel(){
                this.addOrEditKey = false
                this.mask = false
                this.AddKey = false
                this.EditKey = false
                this.daleteKeyShow= false
                this.mes=""
            },
            addkeys(){
                this.addOrEditKey = true
                this.mask = true
                this.key = {
                    id:0,
                    username:'',
                    password:'',
                    codePc:'',
                    userCreate: localStorage.getItem('username'),
                    autoBasicShopee: false,
                    autoDDShopee: false,
                    autoDGShopee:false,
                    autoCrawShopee:false,
                    autoBasicLazada:false,
                    autoDDLazada: false,
                    autoDGLazada: false,
                    autoCrawLazada:false,
                    autoTiktok:false,
                },
                this.AddKey = true
                this.EditKey = false
            },
            editKeys(item){
                this.addOrEditKey = true
                this.mask = true
                this.key = {
                    id:item.id,
                    username: item.username,
                    password: item.password,
                    codePc: item.codePc,
                    userCreate: item.userCreate,
                    autoBasicShopee: item.autoBasicShopee,
                    autoDDShopee: item.autoDDShopee,
                    autoDGShopee:item.autoDGShopee,
                    autoCrawShopee:item.autoCrawShopee,
                    autoBasicLazada:item.autoBasicLazada,
                    autoDDLazada: item.autoDDLazada,
                    autoDGLazada: item.autoDGLazada,
                    autoCrawLazada:item.autoCrawLazada,
                    autoTiktok:item.autoTiktok,
                }
                this.AddKey = false
                this.EditKey = true
                console.log(this.key)
                
            },
            delKey(item){
                this.daleteKeyShow = true
                this.mask = true
                this.key.username = item.username
                this.key.id = item.id
            },
            AcceptDeleteKey(){
                axios.delete(process.env.API_DELETEKREYS + this.key.id, {
                headers: {
                    'Authorization': this.token,
                    'Content-Type': 'application/json'
                }
                })
                .then(response => {
                alert('xóa thành công')
                this.getDataApiListKeys()
                this.cancel();
                console.log(response.data);
                })
                .catch(error => {
                console.error(error);
                });
            },
            addDataKey(){
                if(this.key.username.length < 6){
                    this.mes="TK không được nhỏ hơn 6 ký tự"
                }else if(this.key.password.length < 6){
                    this.mes="MK không được nhỏ hơn 6 ký tự"
                }else if(this.key.codePc.length <= 0){
                    this.mes="Vui lòng nhập mã máy"
                }
                else{
                    this.mes=""    
                    const payload = {
                    "username": this.key.username,
                    "password": this.key.password,
                    "codePc": this.key.codePc,
                    "userCreate": this.key.userCreate,
                    "autoBasicShopee": this.key.autoBasicShopee,
                    "autoDDShopee": this.key.autoDDShopee,
                    "autoDGShopee": this.key.autoDGShopee,
                    "autoCrawShopee": this.key.autoCrawShopee,
                    "autoBasicLazada": this.key.autoBasicLazada,
                    "autoDDLazada": this.key.autoDDLazada,
                    "autoDGLazada": this.key.autoDGLazada,
                    "autoCrawLazada": this.key.autoCrawLazada,
                    "autoTiktok": this.key.autoTiktok
                    };

                    axios.post(process.env.API_ADDKEYS, payload, {
                    headers: {
                        'Authorization': this.token,
                        'Content-Type': 'application/json'
                    }
                    })
                    .then(response => {
                    // console.log(response.data);
                    if(response.data.err==0){
                        this.key.username = ''
                        this.key.password=''
                        this.key.codePc=''
                        alert('Thêm key thành công')
                        this.getDataApiListKeys()
                    }
                    else{
                        alert('Có lỗi xảy ra, xin vui lòng thử lại')
                    }
                    })
                    .catch(error => {
                    console.error(error);
                    });
                }
            },
            editDataKey(){
                if(this.key.username.length < 6){
                    this.mes="TK không được nhỏ hơn 6 ký tự"
                }else if(this.key.password.length < 6){
                    this.mes="MK không được nhỏ hơn 6 ký tự"
                }else if(this.key.codePc.length <= 0){
                    this.mes="Vui lòng nhập mã máy"
                }
                else{
                    this.mes=""    
                    const payload = {
                    "username": this.key.username,
                    "password": this.key.password,
                    "codePc": this.key.codePc,
                    "userCreate": this.key.userCreate,
                    "autoBasicShopee": this.key.autoBasicShopee,
                    "autoDDShopee": this.key.autoDDShopee,
                    "autoDGShopee": this.key.autoDGShopee,
                    "autoCrawShopee": this.key.autoCrawShopee,
                    "autoBasicLazada": this.key.autoBasicLazada,
                    "autoDDLazada": this.key.autoDDLazada,
                    "autoDGLazada": this.key.autoDGLazada,
                    "autoCrawLazada": this.key.autoCrawLazada,
                    "autoTiktok": this.key.autoTiktok
                    };

                    axios.put(process.env.API_EDITKEYS + this.key.id, payload, {
                    headers: {
                        'Authorization': this.token,
                        'Content-Type': 'application/json'
                    }
                    })
                    .then(response => {
                    // console.log(response.data);
                    if(response.data.err==0){
                        alert('Lưu key thành công')
                        this.getDataApiListKeys()
                    }
                    else{
                        alert('Có lỗi xảy ra, xin vui lòng thử lại')
                    }
                    })
                    .catch(error => {
                    console.error(error);
                    });
                }

            },
            getDataApiListKeys(){
                axios.defaults.headers.common['Authorization'] = `${this.token}`;
                axios.get(process.env.API_GETKEYS + this.username)
                .then(response => {
                    // console.log(response.data.Keys)
                    this.dataKey = response.data.Keys
                })
                .catch(error => {
                console.log(error);
        });
            }
        },
        mounted(){
        document.title = this.pageTitle;
        axios.defaults.headers.common['Authorization'] = `${this.token}`;
        axios.get(process.env.API_GETKEYS + this.username)
        .then(response => {
            // console.log(response.data.Keys)
            this.dataKey = response.data.Keys
        })
        .catch(error => {
            console.log(error);
        });
      }
    }
    
</script>
  
<style scoped>
.validateAddKey{
    color: red;
    padding: 10px 0px;
    font-size: 13px;
}
.dashboard{
    background: #1e1e20;
    min-height: 100vh;
    color: white;
}
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td, th {
  border: 1px solid #dddddd;
  text-align: center;
  padding: 8px;
}

/* tr:nth-child(even) {
  background-color: #dddddd;
} */
.info .key{
    border: 1px solid white;
}
.title_fil{
    padding: 10px 10px;
}
.title_key {
    display: flex;
}
.btnAddKey{
    cursor: pointer;
    padding: 0px 10px;
    text-decoration: underline;
}
.btnAddKey:hover{
    color: rgb(68, 255, 47);
}
.filByUsername button{
    width: 50px;
    font-size: 15px;
    height: 20px;
    border: 1px solid white;
    background: transparent;
    color: white;
    cursor: pointer;
}
.filByUsername button:hover{
    color: aqua;
    border: 1px solid aqua;
}
.filByUsername input{
    font-size: 15px;
    height: 20px;
    background: transparent;
    border: 1px solid white;
    color: white;
    outline: none;
    padding: 0px 3px;
}
.listKey{
    overflow: auto;
}
.edit_delete{
    display: flex;
    justify-content: space-around;
}
.DeleteKey,.EditKey{
    text-decoration: underline;
    cursor: pointer;
}
.DeleteKey:hover{
    color: red;
}
.EditKey:hover{
    color: darkgoldenrod;
}
.mask{
    background: grey;
    width: 100%;
    height: 100vh;
    opacity: 0.5;
    z-index: 1;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.addOrEditKey, .deteteKeyAccess{
    width: 250px;
    padding: 10px 10px;
    background: rgb(0, 0, 0);
    z-index: 2;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;

}
.tk_mk_code .tk_mk_code_key{
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
}
.tk_mk_code .tk_mk_code_key input{
    outline: none;
    background: transparent;
    border: 1px solid white;
    padding: 0px 1px;
    color: white;
    text-align: center;
}
.toolAuto .tool_SL{
    margin-bottom: 5px;
    display: flex;
    justify-content: space-between;
}
.btn_add_cancel_edit, .access_cancel{
    margin-top: 10px;
    border-top: 1px solid white;
    display: flex;
    justify-content: flex-end;
    padding: 10px 5px 0px 0px;
}
.btnAddKey, .btn_add_cancel_edit button,.deteteKeyAccess .access_cancel button {
    margin: 0px 0px 5px 5px;
    padding: 2px 4px;
    color: white;
    background:transparent;
    border: 1px solid white;
    cursor: pointer;
}
</style>
  