<template>
    <div class="navbar">
        <div class="user">
            <div class="iconUser"><i class="fa-regular fa-user"></i></div>
            <span class="username">{{ username }}</span>
            <!-- <button class="logout-button" @click="logout">Đăng xuất</button> -->
        </div>
        <div class="logo"><img src="../../assets/LogoBrand.png" alt=""></div>
        <div class="logoutClick">
          <a class="logout" @click="logout" href="#">Đăng xuất</a>
        </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'NavBarAdmin',
    props: {
      msg: String
    },
    data() {
    return {
        username: localStorage.getItem('name'), // Replace with your username data
      };
    },
    methods: {
        logout() {
            localStorage.clear();
            this.$router.push('/admin');
        },
    },
    computed: {
    
    },
  }

  </script>
  
  <style scoped>
  .navbar {
  background-color: #161618;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  color: white;
}

.logo img{
  width: 50px;
}

.user {
  display: flex;
  align-items: center;
}
.iconUser {
  border: 1px solid white;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  text-align: center;
  margin: 0px 10px;
  line-height: 25px;
  cursor: pointer;
}
.username {
  margin-right: 10px;
}
.logoutClick{
    cursor: pointer;
    color: white;
}
.logoutClick a{
    cursor: pointer;
    color: white;
}
.logout-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: #555;
  text-decoration: underline;
}
  </style>
  