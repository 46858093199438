import Vue from 'vue'
import Router from 'vue-router'
import axios from 'axios'

import Home from '../views/home/home.vue'
import Login from '../views/admin/login.vue'
import Dashboard from '../views/admin/dashboard.vue'

Vue.use(Router)

const router = new Router({
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
        path: '/admin',
        name: 'login',
        component: Login
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        component: Dashboard,
        meta: {
            requiresAuth: true
        }
    },      
  ]
})

router.beforeEach((to, from, next) => {
    const token = localStorage.getItem('token')
    if (to.matched.some(record => record.meta.requiresAuth) && !token) {
        next('/admin')
    } else if (to.path === '/admin' && token) {
        axios.defaults.headers.common['Authorization'] = `${token}`;
        axios.get(process.env.API_CHECKUSER)
        .then(response => {
            // console.log(response.data) 
            let err = response.data.err
            if(err == 0){
                localStorage.setItem('id', response.data.userData.id);
                localStorage.setItem('username', response.data.userData.username);
                localStorage.setItem('name', response.data.userData.name);
                localStorage.setItem('role_code', response.data.userData.role_code);
                next('/dashboard')
            }
            else {
                next('/admin')
            }
          })
          .catch(error => {
            console.log(error);
            next()
          });
        // next('/dashboard')
    }else if (to.path === '/dashboard') {
        axios.defaults.headers.common['Authorization'] = `${token}`;
        axios.get(process.env.API_CHECKUSER)
          .then(response => {
            // console.log(response.data.userData) 
            let err = response.data.err
            if(err == 0){
              localStorage.setItem('id', response.data.userData.id);
              localStorage.setItem('username', response.data.userData.username);
              localStorage.setItem('name', response.data.userData.name);
              localStorage.setItem('role_code', response.data.userData.role_code);
              next()
            }
            else {
              next('/admin')
            }
          })
          .catch(error => {
            console.log(error);
            next('/admin')
          });
      } else {
        next()
    }
})

export default router;