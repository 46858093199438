<template>
    <div class="">
        <h1>Hello world</h1>
    </div>
</template>
  
<script>
    export default {
        name: 'AppHome',
        
    }
</script>
  
<style scoped>
</style>
  