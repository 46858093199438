<template>
  <div id="app">
   <router-view></router-view>
  </div>
</template>

<script>
// import Home from "./views/home/home.vue"
export default {
  name: 'App',
  components: {
    // Home
  }
}
</script>

<style>
@import '~@fortawesome/fontawesome-free/css/all.css';
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
